import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
// Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function Contact() {
    const { t, i18n } = useTranslation();

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [projectName, setProjectName] = useState('');
    const [msg, setMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            name_input: name,
            number_input: number,
            projectName_input: projectName,
            msg_input: msg,
        };

        emailjs.send(
            'service_6d18obk', 'template_aino6lb',
            templateParams , 'hAY1JmNI-VnSuD7EW'
        ).then((response) => {
            console.log('SUCCESS!');
        }).catch((err) => {
            console.log('ERROR!');
        });
    };


    return (
        <div className='contact-page'>
            <Header />

            <div className='contact'>
                <div className='title-background'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/green-bracket.png')} className='first-bracket' />
                        <h2>{t("contactIntroTitle")}</h2>
                        <img src={require('../Images/Icons/green-bracket.png')} className='second-bracket' />
                    </div>
                </div>

                <div className='socials'>

                    <div className='soc'>
                        <img src={require('../Images/Icons/instagram-black.png')} />
                        <h3>{t("instagram")}</h3>
                        <p>@coder.koala</p>
                    </div>

                    <div className='soc'>
                        <img src={require('../Images/Icons/mail-black.png')} />
                        <h3>{t("email")}</h3>
                        <p>coderkoalaweb@gmail.com</p>
                    </div>

                    <div className='soc'>
                        <img src={require('../Images/Icons/whatsapp-black.png')} />
                        <h3>{t("whatsapp")}</h3>
                        <p>0503044882</p>
                    </div>
                </div>

                <div className='form-section'>
                    <div className='container'>
                        <div className='title'>
                            <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                            <h2>{t("formTitle")}</h2>
                            <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                            <p>{t("formPara")}</p>
                        </div>

                        <div className='form'>
                            <img src={require('../Images/Icons/green-bracket.png')} className='bracket-1' />
                            <img src={require('../Images/Icons/green-bracket.png')} className='bracket-2' />
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label for="name">{t("formNameInput")}</label>
                                    <input type='text' id='name' value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>

                                <div>
                                    <label for="phone">{t("formPhoneInput")}</label>
                                    <input type='text' id='phone' value={number} onChange={(e) => setNumber(e.target.value)} required />
                                </div>

                                <div>
                                    <label for="project-name">{t("formProjectInput")}</label>
                                    <input type='text' id='projectName' value={projectName} onChange={(e) => setProjectName(e.target.value)} required />
                                </div>

                                <div>
                                    <label for="msg">{t("formMsgInput")}</label>
                                    <textarea type='text' id='msg' value={msg} onChange={(e) => setMsg(e.target.value)} required
                                    placeholder={t("formMsgInputPlaceholder")} />
                                </div>

                                <input type='submit' className='btn' value={t("formSubmitBtn")} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Contact;