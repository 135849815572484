import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function Home() {
    const { t, i18n } = useTranslation();

    return (
        <div className='home'>
            <Header />

            <div className='home-intro'>
                <h1>{t('introH1Ele')} <b>{t("coderkoala")}</b></h1>
                <p>{t('introParaEle')}</p>
                <Link to="Contact" className='btn'>
                    <h3>{t('introBtn')}</h3>
                </Link>
            </div>

            <div className='about'>
                <div className='container'>
                    <div className='text'>
                        <div className='title'>
                            <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                            <h2>{t('aboutTitle')}</h2>
                            <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                        </div>

                        <p>{t('aboutPara-1')}</p>
                        <p>{t('aboutPara-2')}</p>
                        <Link to="About" className='btn'>
                            <h3>{t('aboutBtn')}</h3>
                        </Link>
                    </div>

                    <div className='image'>
                        <img src={require('../Images/Logo/logo.png')} />
                    </div>
                </div>
            </div>

            <div className='some-features'>
                <div className='container'>
                    <h1>{t("homeFeatureTitleSec")}</h1>

                    <div className='features'>
                        <div className='feature'>
                            <img src={require("../Images/Icons/plan.png")} />
                            <h3>{t("homeFeatureNo1Title")}</h3>
                            <p>{t("homeFeatureNo1Para")}</p>
                        </div>

                        <div className='feature'>
                            <img src={require("../Images/Icons/graphic-design.png")} />
                            <h3>{t("homeFeatureNo2Title")}</h3>
                            <p>{t("homeFeatureNo2Para")}</p>
                        </div>

                        <div className='feature'>
                            <img src={require("../Images/Icons/update2.png")} />
                            <h3>{t("homeFeatureNo3Title")}</h3>
                            <p>{t("homeFeatureNo3Para")}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='latest-projects'>
                <div className='container'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                        <h2>{t("latestProjectsTitle")}</h2>
                        <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                    </div>

                    <div className='projects'>
                        <div className='project'>
                            <div className='images'>
                                <img src={require('../Images/Projects/mjposters-desktop.png')} className='desktop-screen' />
                                <img src={require('../Images/Projects/mjposters-phone.png')} className='phone-screen' />
                            </div>

                            <div className='text'>
                                <h4>{t("project1Title")}</h4>
                                <p>{t("project1Desc")}</p>
                                <Link to="https://mjposters.vercel.app/" className='btn'>
                                    <h3>{t('projectVisitWebsite')}</h3>
                                </Link>
                            </div>
                        </div>

                        <div className='project'>
                            <div className='images'>
                                <img src={require('../Images/Projects/magiccups-desktop.png')} className='desktop-screen' />
                                <img src={require('../Images/Projects/magiccups-phone.png')} className='phone-screen' />
                            </div>

                            <div className='text'>
                                <h4>{t("project2Title")}</h4>
                                <p>{t("project2Desc")}</p>
                                <Link to="https://mugs-store.vercel.app/" className='btn'>
                                    <h3>{t('projectVisitWebsite')}</h3>
                                </Link>
                            </div>
                        </div>

                        <div className='project'>
                            <div className='images'>
                                <img src={require('../Images/Projects/ehteraf-desktop.png')} className='desktop-screen' />
                                <img src={require('../Images/Projects/ehteraf-phone.png')} className='phone-screen' />
                            </div>

                            <div className='text'>
                                <h4>{t("project3Title")}</h4>
                                <p>{t("project1Desc")}</p>
                                <Link to="https://www.e7teraf-office.com/" className='btn'>
                                    <h3>{t('projectVisitWebsite')}</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Home;