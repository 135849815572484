import { useTranslation } from 'react-i18next'; 
import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

function Footer() {
    const { t, i18n } = useTranslation();

    // Check if the screen is small
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth <= 1100);
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <footer>
            <div className="container">
                <div className='logo'>
                    <img src={require('../Images/Logo/whiteLogo.png')} />
                    <p>{t('footerDesc')}</p>
                </div>

                <div className='links'>
                    <ul>
                        <a href="/"><li>{t('link1')}</li></a>
                        <a href="About"><li>{t('link2')}</li></a>
                        <a href="Services"><li>{t('link3')}</li></a>
                        <a href="Contact"><li>{t('link4')}</li></a>
                        {/* <a href="#"><li>المدونة</li></a> */}
                    </ul>
                </div>

                <div className='contact'>
                    <div className='title'>
                        <img src={require('../Images/Icons/green-bracket-title.png')} className='first-bracket' />
                        <h2>{t('footerContactTitle')}</h2>
                        <img src={require('../Images/Icons/green-bracket-title.png')} className='second-bracket' />
                    </div>

                    <Link to="Contact" className='btn'>
                        <h3>{t('footerContactBtn')}</h3>
                    </Link>

                    <div className='socials'>
                        {/* <a href="#"><i class="fa-brands fa-youtube"></i></a> */}
                        <a href=""><i class="fa-brands fa-whatsapp"></i></a>
                        <a href="https://www.instagram.com/coder.koala/"><i class="fa-brands fa-instagram"></i></a>

                    </div>
                </div>
            </div>

            <div className='copyright'>
                <p>All rights reserved - 2024 © CoderKoala</p>
            </div>
        </footer>
    )
}

export default Footer;