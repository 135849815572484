import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 

function Header() {
    const { t, i18n } = useTranslation();
    
    const currentLanguage = i18n.language;
    useEffect(() => {
        if (currentLanguage === "ar") {
            body.style.cssText = "font-family:'Almarai'";
        } else if (currentLanguage === "en") {
            body.style.cssText = "font-family:'Montserrat'";
        }
    }, [currentLanguage]);

    // Change Languages
    let langBar = useRef();
    const openLang = () => {
        if(langBar.current.classList.contains('disNone')) {
            langBar.current.classList.remove('disNone');
            langBar.current.classList.add('disFlex');
        } else {
            langBar.current.classList.add('disNone');
            langBar.current.classList.remove('disFlex');
        }
    }

    let body = document.querySelector('body');
    const changeEN = () => {
        i18n.changeLanguage("en");
        body.style.cssText = "font-family:'Montserrat'";
    }
    const changeAR = () => {
        i18n.changeLanguage("ar");
        body.style.cssText = "font-family:'Almarai'";
    }


    // Check if the screen is small
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth <= 1100);
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let smallHeaderLinks = useRef();
    let smallHeaderLinksBtn = () => {
        smallHeaderLinks.current.classList.toggle("disNone");
    }

    return (
        <>
            {isSmallScreen ? (
                <header className="small-header">
                    <div className="container">

                        <div className='small-links-language'>
                            <div className='change-language'>
                                <img src={require('../Images/Icons/global.png')} className='global-icon' onClick={openLang} />
                                <div className='langs disNone' ref={langBar}>
                                    <button onClick={changeEN}>English</button>
                                    <button onClick={changeAR}>العربية</button>
                                </div>
                            </div>

                            <i class="fa-solid fa-bars bar-icon" onClick={smallHeaderLinksBtn}></i>
                            <ul className='links disNone' ref={smallHeaderLinks}>
                                <a href="/"><li>{t('link1')}</li></a>
                                <a href="About"><li>{t('link2')}</li></a>
                                <a href="Services"><li>{t('link3')}</li></a>
                                <a href="Contact"><li>{t('link4')}</li></a>
                            </ul>
                        </div>
                        
                        <a href="/">
                            <div className='logo'>
                                <img src={require('../Images/Logo/logo.png')} />
                            </div>
                        </a>

                    </div>
                </header>
            ) : (
                <header>
                    <div className="container">
                        <div className='links-language'>
                            <div className='change-language'>
                                <img src={require('../Images/Icons/global.png')} className='global-icon' onClick={openLang} />
                                <div className='langs disNone' ref={langBar}>
                                    <button onClick={changeEN}>English</button>
                                    <button onClick={changeAR}>العربية</button>
                                </div>
                            </div>

                            <ul className='links'>
                                <a href="/"><li>{t('link1')}</li></a>
                                <a href="About"><li>{t('link2')}</li></a>
                                <a href="Services"><li>{t('link3')}</li></a>
                                <a href="Contact"><li>{t('link4')}</li></a>
                            </ul>
                        </div>

                        <a href="/">
                            <div className='logo'>
                                <img src={require('../Images/Logo/logo.png')} />
                            </div>
                        </a>
                    </div>
                </header>
            )}
        </>
    )
}

export default Header;