import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function About() {
    const { t, i18n } = useTranslation();

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [projectName, setProjectName] = useState('');
    const [msg, setMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            name_input: name,
            number_input: number,
            projectName_input: projectName,
            msg_input: msg,
        };

        emailjs.send(
            'service_6d18obk', 'template_aino6lb',
            templateParams , 'hAY1JmNI-VnSuD7EW'
        ).then((response) => {
            console.log('SUCCESS!');
        }).catch((err) => {
            console.log('ERROR!');
        });
    };

    return (
        <div className='about'>
            <Header />

            <div className='intro'>
                <div className='right-bracket'>
                    <img src={require('../Images/Icons/green-bracket.png')} />
                    <img src={require('../Images/Icons/green-bracket.png')} />
                </div>

                <div className='text'>
                    <h1>{t("aboutIntroH1")}</h1>
                    <p>{t("aboutIntroPara")}</p>
                </div>

                <div className='left-bracket'>
                    <img src={require('../Images/Icons/green-bracket.png')} />
                    <img src={require('../Images/Icons/green-bracket.png')} />
                </div>
            </div>

            <div className='content'>
                <div className='container'>
                    <div className='mission sec'>
                        <div className='text'>
                            <div className='title'>
                                <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                                <h2>{t("ourMissionTitle")}</h2>
                                <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                            </div>

                            <p>{t("ourMissionPara1")}</p>
                            <p>{t("ourMissionPara2")}</p>
                        </div>

                        <div className='image'>
                            <img src={require('../Images/ABOUT Page/image2.jpg')} />
                        </div>
                    </div>

                    <div className='your-project sec'>
                        <div className='image'>
                            <img src={require('../Images/ABOUT Page/image1.jpg')} />
                        </div>

                        <div className='text'>
                            <div className='title'>
                                <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                                <h2>{t("yourProjectTitle")}</h2>
                                <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                            </div>

                            <p>{t("yourProjectPara1")}</p>
                            <p>{t("yourProjectPara2")}</p>
                            <p>{t("yourProjectPara3")}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='features'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                        <h2>{t("featuresTitle")}</h2>
                        <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                    </div>
                <div className='container'>
                    <div className='features-list'>

                        <div className='fea'>
                            <img src={require('../Images/Icons/uiux.png')} />
                            <h3>{t("feature1Title")}</h3>
                            <p>{t("feature1Desc")}</p>
                        </div>

                        <div className='fea'>
                            <img src={require('../Images/Icons/web.png')} />
                            <h3>{t("feature2Title")}</h3>
                            <p>{t("feature2Desc")}</p>
                        </div>

                        <div className='fea'>
                            <img src={require('../Images/Icons/24hours.png')} />
                            <h3>{t("feature3Title")}</h3>
                            <p>{t("feature3Desc")}</p>
                        </div>

                        <div className='fea'>
                            <img src={require('../Images/Icons/solutions.png')} />
                            <h3>{t("feature4Title")}</h3>
                            <p>{t("feature4Desc")}</p>
                        </div>

                        <div className='fea'>
                            <img src={require('../Images/Icons/pricing.png')} />
                            <h3>{t("feature5Title")}</h3>
                            <p>{t("feature5Desc")}</p>
                        </div>

                        <div className='fea'>
                            <img src={require('../Images/Icons/update.png')} />
                            <h3>{t("feature6Title")}</h3>
                            <p>{t("feature6Desc")}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='contact'>
                <div className='title-background'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/green-bracket.png')} className='first-bracket' />
                        <h2>{t("contactIntroTitle")}</h2>
                        <img src={require('../Images/Icons/green-bracket.png')} className='second-bracket' />
                    </div>
                </div>

                <div className='socials'>

                    <div className='soc'>
                        <img src={require('../Images/Icons/instagram-black.png')} />
                        <h3>{t("instagram")}</h3>
                        <p>@coder.koala</p>
                    </div>

                    <div className='soc'>
                        <img src={require('../Images/Icons/mail-black.png')} />
                        <h3>{t("email")}</h3>
                        <p>coderkoalaweb@gmail.com</p>
                    </div>

                    <div className='soc'>
                        <img src={require('../Images/Icons/whatsapp-black.png')} />
                        <h3>{t("whatsapp")}</h3>
                        <p>0503044882</p>
                    </div>
                </div>

                <div className='form-section'>
                    <div className='container'>
                        <div className='title'>
                            <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                            <h2>{t("formTitle")}</h2>
                            <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                            <p>{t("formPara")}</p>
                        </div>

                        <div className='form'>
                            <img src={require('../Images/Icons/green-bracket.png')} className='bracket-1' />
                            <img src={require('../Images/Icons/green-bracket.png')} className='bracket-2' />
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label for="name">{t("formNameInput")}</label>
                                    <input type='text' id='name' value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>

                                <div>
                                    <label for="phone">{t("formPhoneInput")}</label>
                                    <input type='text' id='phone' value={number} onChange={(e) => setNumber(e.target.value)} required />
                                </div>

                                <div>
                                    <label for="project-name">{t("formProjectInput")}</label>
                                    <input type='text' id='projectName' value={projectName} onChange={(e) => setProjectName(e.target.value)} required />
                                </div>

                                <div>
                                    <label for="msg">{t("formMsgInput")}</label>
                                    <textarea type='text' id='msg' value={msg} onChange={(e) => setMsg(e.target.value)} required
                                    placeholder={t("formMsgInputPlaceholder")} />
                                </div>

                                <input type='submit' className='btn' value={t("formSubmitBtn")} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default About;