import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// Components
import Header from '../Components/Header';
import Footer from '../Components/Footer';

function Services() {
    const { t, i18n } = useTranslation();

    const handleClick = (event) => {
        const queElement = event.currentTarget;
        const answerElement = queElement.querySelector('.answer');
        if(answerElement.classList.contains('disNone')) {
            answerElement.classList.remove('disNone')
            answerElement.classList.add('disBlock')
        } else {
            answerElement.classList.add('disNone')
            answerElement.classList.remove('disBlock')
        }
    };

    return (
        <div className='services'>
            <Header />

            <div className='intro'>
                <div className='right-bracket'>
                    <img src={require('../Images/Icons/green-bracket.png')} />
                    <img src={require('../Images/Icons/green-bracket.png')} />
                </div>

                <div className='text'>
                    <h1>{t("servicesIntroTitle")}</h1>
                    <p>{t("servicesIntroDesc")}</p>
                </div>

                <div className='left-bracket'>
                    <img src={require('../Images/Icons/green-bracket.png')} />
                    <img src={require('../Images/Icons/green-bracket.png')} />
                </div>
            </div>

            <div className='some-services'>
                <div className='container'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                        <h2>{t("typesTitle")}</h2>
                        <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                    </div>

                    <div className='websites-type-list'>
                        <div className='type'>
                            <img src={require('../Images/Icons/cart.png')} />
                            <h3>{t("type1Title")}</h3>
                            <p>{t("type1Desc")}</p>
                            <Link to="/Contact" className='btn'>
                                <h3>{t('typeBtn')}</h3>
                            </Link>
                        </div>

                        <div className='type fill'>
                            <img src={require('../Images/Icons/online-learning.png')} />
                            <h3>{t("type2Title")}</h3>
                            <p>{t("type2Desc")}</p>
                            <Link to="/Contact" className='btn'>
                                <h3>{t('typeBtn')}</h3>
                            </Link>
                        </div>

                        <div className='type'>
                            <img src={require('../Images/Icons/personal.png')} />
                            <h3>{t("type3Title")}</h3>
                            <p>{t("type3Desc")}</p>
                            <Link to="/Contact" className='btn'>
                                <h3>{t('typeBtn')}</h3>
                            </Link>
                        </div>

                        <div className='type fill'>
                            <img src={require('../Images/Icons/services.png')} />
                            <h3>{t("type4Title")}</h3>
                            <p>{t("type4Desc")}</p>
                            <Link to="/Contact" className='btn'>
                                <h3>{t('typeBtn')}</h3>
                            </Link>
                        </div>

                        <div className='type'>
                            <img src={require('../Images/Icons/blog.png')} />
                            <h3>{t("type5Title")}</h3>
                            <p>{t("type5Desc")}</p>
                            <Link to="/Contact" className='btn'>
                                <h3>{t('typeBtn')}</h3>
                            </Link>
                        </div>

                        <div className='type fill'>
                            <img src={require('../Images/Icons/more.png')} />
                            <h3>{t("type6Title")}</h3>
                            <p>{t("type6Desc")}</p>
                            <Link to="/Contact" className='btn'>
                                <h3>{t('typeBtn')}</h3>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='latest-projects'>
                <div className='container'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                        <h2>{t("latestProjectsTitle")}</h2>
                        <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                    </div>

                    <div className='projects'>
                        <div className='project'>
                            <div className='images'>
                                <img src={require('../Images/Projects/mjposters-desktop.png')} className='desktop-screen' />
                                <img src={require('../Images/Projects/mjposters-phone.png')} className='phone-screen' />
                            </div>

                            <div className='text'>
                                <h4>{t("project1Title")}</h4>
                                <p>{t("project1Desc")}</p>
                                <Link to="https://mjposters.vercel.app/" className='btn'>
                                    <h3>{t('projectVisitWebsite')}</h3>
                                </Link>
                            </div>
                        </div>

                        <div className='project'>
                            <div className='images'>
                                <img src={require('../Images/Projects/magiccups-desktop.png')} className='desktop-screen' />
                                <img src={require('../Images/Projects/magiccups-phone.png')} className='phone-screen' />
                            </div>

                            <div className='text'>
                                <h4>{t("project2Title")}</h4>
                                <p>{t("project2Desc")}</p>
                                <Link to="https://mugs-store.vercel.app/" className='btn'>
                                    <h3>{t('projectVisitWebsite')}</h3>
                                </Link>
                            </div>
                        </div>

                        <div className='project'>
                            <div className='images'>
                                <img src={require('../Images/Projects/ehteraf-desktop.png')} className='desktop-screen' />
                                <img src={require('../Images/Projects/ehteraf-phone.png')} className='phone-screen' />
                            </div>

                            <div className='text'>
                                <h4>{t("project3Title")}</h4>
                                <p>{t("project1Desc")}</p>
                                <Link to="https://www.e7teraf-office.com/" className='btn'>
                                    <h3>{t('projectVisitWebsite')}</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tools'>
                <div className='container'>
                    <div className='title'>
                        <h2><b>{t("coderkoala")}</b> {t("toolsTitle")}</h2>
                    </div>

                    <div className='tools-imgs'>
                        <img src={require('../Images/Tools/HTML.png')} />
                        <img src={require('../Images/Tools/CSS.png')} />
                        <img src={require('../Images/Tools/JS.png')} />
                        <img src={require('../Images/Tools/REACT.png')} />
                        <img src={require('../Images/Tools/SASS.png')} />
                        <img src={require('../Images/Tools/FIREBASE.png')} />
                        <img src={require('../Images/Tools/FIGMA.png')} />
                    </div>
                </div>
            </div>

            <div className='frequently-questions'>
                <div className='container'>
                    <div className='title center-title'>
                        <img src={require('../Images/Icons/bracket.png')} className='first-bracket' />
                        <h2>{t("questionsTitle")}</h2>
                        <img src={require('../Images/Icons/bracket.png')} className='second-bracket' />
                    </div>

                    <div className='questions-list'>
                        <div className='que' onClick={handleClick}>
                            <div className='question'>
                                <h3>{t("question1")}</h3>
                                <img src={require('../Images/Icons/plus.png')} />
                            </div>

                            <p className='answer disNone'>{t("answer1")}</p>
                        </div>

                        <div className='que' onClick={handleClick}>
                            <div className='question'>
                                <h3>{t("question2")}</h3>
                                <img src={require('../Images/Icons/plus.png')} />
                            </div>

                            <p className='answer disNone'>{t("answer2")}</p>
                        </div>

                        <div className='que' onClick={handleClick}>
                            <div className='question'>
                                <h3>{t("question3")}</h3>
                                <img src={require('../Images/Icons/plus.png')} />
                            </div>

                            <p className='answer disNone'>{t("answer3")}</p>
                        </div>

                        <div className='que' onClick={handleClick}>
                            <div className='question'>
                                <h3>{t("question4")}</h3>
                                <img src={require('../Images/Icons/plus.png')} />
                            </div>

                            <p className='answer disNone'>{t("answer4")}</p>
                        </div>

                        <div className='que' onClick={handleClick}>
                            <div className='question'>
                                <h3>{t("question5")}</h3>
                                <img src={require('../Images/Icons/plus.png')} />
                            </div>

                            <p className='answer disNone'>{t("answer5")}</p>
                        </div>

                        <div className='que' onClick={handleClick}>
                            <div className='question'>
                                <h3>{t("question6")}</h3>
                                <img src={require('../Images/Icons/plus.png')} />
                            </div>

                            <p className='answer disNone'>{t("answer6")}</p>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Services;