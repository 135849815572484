import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// Translation Framework
import './i18next';

// Pages
import Home from './Pages/home';
import About from './Pages/about';
import Services from './Pages/services';
import Contact from './Pages/contact';

// Style Files
import './Styles/all.min.css';
import './Styles/Global-Rules/global-rules.css';
import './Styles/Home/home.css';
import './Styles/About/about.css';
import './Styles/Contact/contact.css';
import './Styles/Services/services.css';

// Components Style Files
import './Styles/Components-Style/Header/header.css';
import './Styles/Components-Style/Footer/footer.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {path: "/", element: <Home/>,},
  {path: "About", element: <About/>,},
  {path: "Services", element: <Services/>,},
  {path: "Contact", element: <Contact/>,},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
